@import "../../themes/generated/variables.base.scss";

.dashboard-card {

  .dx-card {
    .header {
      margin-bottom: 30px;

      .title {
        color: $base-text-color;
        line-height: 28px;
        font-weight: 500;
        font-size: 24px;
      }

      .description {
        color: rgba($base-text-color, alpha($base-text-color) * 0.7);
        line-height: 18px;
      }
    }
  }
  margin-bottom: 20px;
}
