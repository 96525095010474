.customer-vending-load-product_demanded-not-loaded td{
  background-color: rgba(255, 0, 0, 0.44);
}

.customer-vending-load-product_demanded-not-loaded.dx-row-alt td{
  background-color: rgba(255, 0, 0, 0.62);
}


.customer-vending-load-product_demanded-loaded td{
  background-color: rgba(0, 255, 0, 0.65);
}

.customer-vending-load-product_demanded-loaded.dx-row-alt td{
  background-color: lime;
}

.customer-vending-load-product_demanded-partial-loaded td{
  background-color: rgba(255, 242, 0, 0.65);
}

.customer-vending-load-product_demanded-partial-loaded.dx-row-alt td{
  background-color: rgb(255, 242, 0);
}