@import "../../../themes/generated/variables.base.scss";

.visited-indicator {
  .text {
    color: $base-text-color;
    line-height: 28px;
    font-weight: 500;
    font-size: 24px;
    text-align: center;
  }
}